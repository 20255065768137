"use client";
import React, { FC } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { GQL_GET_TOTAL_FUNDRAISING_BY_PAYMENT_LINK_ID } from "../../gqls";
import { formatRp, REGEX_UUID } from "../../utils";
import LoadingAnimation from "../loading-animation";
import { Icon } from "@iconify/react";
import { Progress } from "../ui/progress";
import { Tooltip, TooltipProvider } from "../ui/tooltip";
import { TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip";

interface DonationReceivedProps {
  paymentLinkId: string;
  isTargetVisible: boolean;
  isV2?: boolean;
}

export const DonationReceived: FC<DonationReceivedProps> = ({
  paymentLinkId,
  isTargetVisible,
  isV2 = false,
}) => {
  const { t } = useTranslation();
  const gparam = {
    pageSize: 1,
    page: 1,
    ...(REGEX_UUID.test(paymentLinkId)
      ? { id: paymentLinkId }
      : { link: paymentLinkId }),
  };

  const { loading, error, data } = useQuery(
    GQL_GET_TOTAL_FUNDRAISING_BY_PAYMENT_LINK_ID,
    {
      context: { public: true },
      variables: gparam,
    }
  );

  const totalFundraising = _.get(
    data,
    "getTotalFundraisingsByPaymentLinkID.totalFundraising"
  );
  const targetFundraising = _.get(
    data,
    "getTotalFundraisingsByPaymentLinkID.target"
  );

  if (error) return `Error! ${error.message}`;
  if (loading)
    return (
      <div className="mb-2">
        <LoadingAnimation twodashline />
      </div>
    );

  return (
    <div className="font-open-sans">
      {isV2 ? (
        <>
          <div className="flex justify-between mb-3 text-[13px]">
            <div className="w-1/2">
              <p>
                {t("word.collected")}: <br />
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="mx-1">
                      <strong className="text-blue-500 flex gap-1 items-center font-semibold mt-1">
                        {formatRp(totalFundraising || 0)}
                        <Icon icon="lucide:info" />
                      </strong>
                    </TooltipTrigger>
                    <TooltipContent
                      side="bottom"
                      className="bg-black text-white max-w-[200px] text-center text-xs border-none z-[9999] rounded-sm py-2 px-3"
                    >
                      {t("fundraising.grossAmount")}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </p>
            </div>
            {isTargetVisible && (
              <div className="w-1/2 text-right">
                <p className="flex flex-col">
                  {t("word.fTarget")}: <br />
                  <strong className="font-semibold">
                    {formatRp(targetFundraising || 0)}
                  </strong>
                </p>
              </div>
            )}
          </div>
          <Progress
            value={
              totalFundraising
                ? (totalFundraising / targetFundraising) * 100
                : 0
            }
            variant="striped"
            className="mb-3 h-2 bg-gray-200"
            classNameIndicator="bg-blue-500"
          />
        </>
      ) : (
        <>
          <Progress
            value={
              totalFundraising
                ? (totalFundraising / targetFundraising) * 100
                : 0
            }
            variant="striped"
            className="mb-2.5 h-2 bg-gray-200"
            classNameIndicator="bg-blue-500"
          />
          <p className="mb-0.5 text-[13px]">
            {t("word.collected")}:{" "}
            <strong className="font-medium">
              {formatRp(totalFundraising || 0)}
            </strong>
          </p>
          {isTargetVisible && (
            <p className="m-0 text-[13px]">
              {t("word.fTarget")}:{" "}
              <strong className="font-medium">
                {formatRp(targetFundraising || 0)}
              </strong>
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default DonationReceived;
