export type FundraisingSubType =
  | "FUNDRAISING_WAQAF"
  | "FUNDRAISING_DONATION"
  | "FUNDRAISING_ZAKAT"
  | "FUNDRAISING_QURBAN";

export enum EFundraisingSubType {
  WAQAF = "FUNDRAISING_WAQAF",
  DONATION = "FUNDRAISING_DONATION",
  ZAKAT = "FUNDRAISING_ZAKAT",
  QURBAN = "FUNDRAISING_QURBAN",
}

export type FundraisingStatus = "open" | "closed" | "active";

export type ZakatType = "ZAKAT_FITR" | "ZAKAT_MAL" | "ZAKAT_PROFESSION";

export enum EZakatType {
  FITR = "ZAKAT_FITR",
  MAL = "ZAKAT_MAL",
  PROFESSION = "ZAKAT_PROFESSION",
}

export type QuickDonation = {
  position: string;
  amount: number;
};

export type Mudhohi = {
  mudhohi: string;
};
