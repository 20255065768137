"use client";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubType } from "@/common/types/enum.type";
import { Card, CardContent, CardImage } from "@/components/ui/card";
import { IPaymentLink } from "@/composables/paymentlink.type";
import {
  convertDateToDays,
  createInternalPath,
  formatRp,
  getCoverImage,
  getFundraisingSubTypePath,
  isMobileScreen,
  truncateText,
} from "@/utils";
import DonationReceived from "@/components/fundraising/donation-received";
import { FundraisingCategoryBadge } from "@/components/category-badge/category-badge";
import { useAppSelector } from "@/lib/redux/hook";
import { EFundraisingSubType } from "@/common/types/fundraising.type";
interface FundraisingCardsProps {
  paymentLinks: IPaymentLink[];
}

export const FundraisingCardsMobile: FC<FundraisingCardsProps> = ({
  paymentLinks,
}) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const getDetailPath = (link: string, subType: SubType) => {
    return createInternalPath(
      `/${getFundraisingSubTypePath(subType)}/v2/${link}`,
      user.paymeLink
    );
  };

  return (
    <div className="flex flex-col justify-center mx-auto gap-5">
      {paymentLinks.map(
        ({
          id,
          link,
          coverImage,
          name,
          category,
          expiredAt,
          fundraisingSettings,
          subType,
          amount,
          multipleImage,
        }) => {
          const { isTargetVisible } = fundraisingSettings
            ? JSON.parse(fundraisingSettings)
            : { isTargetVisible: true };
          const badgeCategory =
            !category && subType === EFundraisingSubType.WAQAF
              ? "Wakaf"
              : category;
          const imageSrc = getCoverImage(coverImage, multipleImage, "lg");

          return (
            <div key={id}>
              <Card className="bg-white rounded-[3.25px]">
                <Link
                  href={getDetailPath(link, subType)}
                  className="flex flex-col max-h-max"
                >
                  <CardImage
                    src={imageSrc}
                    alt={`Cover ${name}`}
                    className="w-full h-full min-h-[130px] max-h-[145px] object-cover rounded-t-[3px] sm:max-h-[180px]"
                  />
                  <div className="p-6 flex flex-col items-center">
                    <h5 className="mb-[5px] truncate font-semibold">{name}</h5>
                    {subType === EFundraisingSubType.QURBAN ? (
                      <h5 className="mb-[5px] truncate">
                        {formatRp(amount)} / {t("word.tail")}
                      </h5>
                    ) : (
                      <>
                        <FundraisingCategoryBadge category={badgeCategory} />
                        <div className="w-full mt-2.5">
                          <DonationReceived
                            paymentLinkId={id}
                            isTargetVisible={isTargetVisible}
                          />
                        </div>
                        <div className="mt-0.5">
                          <span className="text-xs font-open-sans">
                            {t("form.remTime")}:{" "}
                            <strong className="font-medium">
                              {expiredAt
                                ? convertDateToDays(expiredAt, true, t)
                                : t("global.unlimited")}
                            </strong>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </Link>
              </Card>
            </div>
          );
        }
      )}
    </div>
  );
};

export const FundraisingCardsV2: FC<FundraisingCardsProps> = ({
  paymentLinks,
}) => {
  const { t } = useTranslation();
  const [maxText, setMaxText] = useState(35);
  const user = useAppSelector((state) => state.user);

  const getDetailPath = (link: string, subType: SubType) => {
    return createInternalPath(
      `/${getFundraisingSubTypePath(subType)}/v2/${link}`,
      user.paymeLink
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 375 && window.innerWidth <= 430) {
        setMaxText(28);
      } else {
        setMaxText(35);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobileScreen()) {
    return <FundraisingCardsMobile paymentLinks={paymentLinks} />;
  }

  return (
    <div className="flex flex-col justify-center mx-auto gap-2.5">
      {paymentLinks.map(
        ({
          id,
          link,
          coverImage,
          multipleImage,
          name,
          category,
          expiredAt,
          fundraisingSettings,
          subType,
          amount,
        }) => {
          const { isTargetVisible } = fundraisingSettings
            ? JSON.parse(fundraisingSettings)
            : { isTargetVisible: true };
          const badgeCategory =
            !category && subType === "FUNDRAISING_WAQAF" ? "Wakaf" : category;
          const imageSrc = getCoverImage(coverImage, multipleImage, "lg");

          return (
            <div key={id}>
              <Card className="bg-white rounded-[3.25px]">
                <Link
                  href={getDetailPath(link, subType)}
                  className="flex overflow-hidden min-h-[130px] max-h-[145px] h-min"
                >
                  <CardImage
                    src={imageSrc}
                    alt={`Cover ${name}`}
                    className="w-[50%] max-h-[145px] h-min my-auto object-contain rounded-t-none"
                  />
                  <CardContent className="w-[50%] text-left pt-[7px] px-4 pb-[5px]">
                    <h5 className="mb-[5px] text-ellipsis whitespace-nowrap overflow-hidden font-medium text-sm">
                      {truncateText(name, maxText)}
                    </h5>
                    {subType === "FUNDRAISING_QURBAN" ? (
                      <h5 className="mb-[5px] text-ellipsis whitespace-nowrap overflow-hidden text-sm">
                        {formatRp(amount)} / {t("word.tail")}
                      </h5>
                    ) : (
                      <>
                        <FundraisingCategoryBadge
                          category={badgeCategory}
                          className="mb-[10px]"
                        />
                        <DonationReceived
                          paymentLinkId={id}
                          isTargetVisible={isTargetVisible}
                        />
                        <span className="text-xs font-open-sans">
                          {t("form.remTime")}:{" "}
                          <strong>
                            <small className="font-medium">
                              {expiredAt
                                ? convertDateToDays(expiredAt, true, t)
                                : t("global.unlimited")}
                            </small>
                          </strong>
                        </span>
                      </>
                    )}
                  </CardContent>
                </Link>
              </Card>
            </div>
          );
        }
      )}
    </div>
  );
};

const FundraisingCards: FC<FundraisingCardsProps> = ({ paymentLinks }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const getDetailPath = (link: string, subType: SubType) => {
    return createInternalPath(
      `/${getFundraisingSubTypePath(subType)}/${link}`,
      user.paymeLink
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mx-auto gap-x-4 gap-y-2.5">
      {paymentLinks.map(
        ({
          id,
          link,
          coverImage,
          multipleImage,
          name,
          expiredAt,
          fundraisingSettings,
          subType,
        }) => {
          const { isTargetVisible } = fundraisingSettings
            ? JSON.parse(fundraisingSettings)
            : { isTargetVisible: true };
          const imageSrc = getCoverImage(coverImage, multipleImage, "lg");

          return (
            <Card className="rounded-[3.25px] w-full" key={id}>
              <Link
                className="text-gray-900 no-underline"
                href={getDetailPath(link, subType)}
              >
                <CardImage
                  src={imageSrc}
                  alt="cover image"
                  className="rounded-[3.25px]"
                />
                <CardContent className="text-left">
                  <h2 className="font-medium mb-4 mt-6">{name}</h2>
                  <DonationReceived
                    paymentLinkId={id}
                    isTargetVisible={isTargetVisible}
                  />
                  <div>
                    <small className="text-gray-700 text-[11px] font-medium">
                      <strong>
                        {expiredAt
                          ? convertDateToDays(expiredAt, true, t)
                          : t("global.unlimited")}
                      </strong>
                    </small>
                  </div>
                </CardContent>
              </Link>
            </Card>
          );
        }
      )}
    </div>
  );
};

export default FundraisingCards;
