"use client";
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation';
import { IPaymentLink, PaymentLinkType } from '@/composables/paymentlink.type';
import { useLazyQuery } from '@apollo/client';
import { GQL_GET_PAYMENT_LINK_PAGE } from '@/gqls';
import ProductCard from './product-card';
import { productOptionsHeader } from '@/utils';
import ButtonLoadMore from './button-load-more';
import { cn } from '@/lib/utils';

type ProductsType = {
  products: IPaymentLink[];
  username: string;
  isProductCatalog?: boolean;
  type?: PaymentLinkType;
  totalProducts?: number;
}

const DEBOUNCE_DELAY = 200;

const Products = ({ products, username, isProductCatalog, type, totalProducts = 0 }: ProductsType) => {
  const searchParams = useSearchParams();
  const queryType = searchParams?.get('type');
  const querySearch = searchParams?.get('search');
  const pageSize = searchParams.get('size') || 9;
  const queryTag = searchParams.get('tag');

  const [loadProducts, { data }] = useLazyQuery(GQL_GET_PAYMENT_LINK_PAGE, {
    fetchPolicy: 'network-only',
  });
  const [productsMerchant, setProductsMerchant] = useState<IPaymentLink[]>(products)
  const [loadingState, setLoadingState] = useState<'idle' | 'loading'>('idle');

  useEffect(() => {
    if (!isProductCatalog) handleTypeFetch()
  }, [queryType, queryTag])

  useEffect(() => {
    if (!isProductCatalog) {
      const size = searchParams.get('size');

      const pageSize = size ? Number(size) : 9;

      const handler = setTimeout(() => {
        loadProducts({
          variables: {
            pageSize: pageSize,
            page: 1,
            status: 'active',
            sourcePage: 'old-catalog',
            username: username,
            ...(querySearch && { name: querySearch }), // Send the debounced search query
            ...(queryType && { type: queryType }),
            ...(queryTag && !isProductCatalog && { tag: { name: queryTag } }),
          },
        }).then((response) => {
          setProductsMerchant(response.data?.getPaymentLinkPageByUsername?.items || []); // Update the products state with the results
        })
      }, DEBOUNCE_DELAY);

      // Clean up the timeout if URL changes before the debounce finishes
      return () => {
        clearTimeout(handler);
      };
    }
  }, [querySearch]); // Listen for changes to the URL query

  const handleTypeFetch = async () => {
    setLoadingState('loading');
    console.log({queryType, queryTag})
    const size = searchParams.get('size');

    const pageSize = size ? Number(size) : 9;
    const res = await loadProducts({
      variables: {
        pageSize: pageSize,
        page: 1,
        status: 'active',
        sourcePage: 'old-catalog',
        username: username,
        ...(queryType && { type: queryType }),
        ...(queryTag && !isProductCatalog && { tag: { name: queryTag } }),
        ...(querySearch && !isProductCatalog && { name: querySearch }),
      },
    });
    console.log({res})

    // if (res?.data?.getPaymentLinkPageByUsername?.items?.length) {
      setProductsMerchant(res?.data?.getPaymentLinkPageByUsername?.items)
    // }

    const params = new URLSearchParams(window.location.search);
    const url = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, '', url);
    setLoadingState('idle');
  }

  const handleFetch = async () => {
    setLoadingState('loading');

    const size = searchParams.get('size');
    console.log({queryTag})
    const pageSize = size ? Number(size) + 9 : 18;
    
    // const res = await fetchProducts({ pageSize: pageSize })
    const res = await loadProducts({
      variables: {
        pageSize: pageSize,
        page: 1,
        status: 'active',
        ...(!isProductCatalog && {
          sourcePage: 'old-catalog',
        }),
        ...(queryType && !isProductCatalog && { type: queryType }),
        ...(queryTag && !isProductCatalog && { tag: { name: queryTag } }),
        username: username,
        ...(type && {
          type: type
        }),
        ...(querySearch && !isProductCatalog && { name: querySearch }),
      },
    });
    console.log({res})

    if (res?.data?.getPaymentLinkPageByUsername?.items?.length) {
      setProductsMerchant(res?.data?.getPaymentLinkPageByUsername?.items)
    }

    const params = new URLSearchParams(window.location.search);
    params.delete('size');
    params.set('size', String(pageSize));

    const url = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, '', url);
    setLoadingState('idle');
  }

  console.log({productsMerchant})
  return (
    <>
      <div className={cn(
        'my-5 mx-auto flex flex-wrap gap-8 justify-center container-product-catalog',
      )}>
        {productsMerchant?.length ? productsMerchant?.map((product: IPaymentLink) => {
          const productOption = productOptionsHeader.find((option) => product.type === option.value);

          return (
            <ProductCard
              id={product?.id}
              amount={product?.amount}
              name={product?.name}
              multipleImage={product?.multipleImage}
              coverImage={product?.coverImage}
              type={product?.type}
              productOption={productOption}
              paymeLink={username}
              link={product?.link}
              subType={product?.subType}
              membershipTier={product?.membershipTier}
            />
          )
        })
        : (
          <div className="mx-auto min-h-[20rem] my-auto flex justify-center items-center">
            <h3 className="font-bold text-secondary">Produk Tidak Ditemukan</h3>
          </div>
        )}
      </div>
      {((
        (Number(pageSize || 0)) < data?.getPaymentLinkPageByUsername?.total) || 
        ((Number(pageSize || 0)) < totalProducts) ||
        (loadingState === 'loading')) && 
        (<ButtonLoadMore handleFetch={handleFetch} isLoading={loadingState === 'loading'} />)
      }
    </>
  )
}

export default Products;
