import moment from "moment";
import "moment/locale/id";

export const setDefaultLocale = () => {
  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get("lang");
    moment.locale(lang || "id");
  }
};
