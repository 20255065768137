"use client";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import { Icon } from "@iconify/react";
import { usePathname } from "next/navigation";
import { createInternalPath } from "@/utils";
import { useAppSelector } from "@/lib/redux/hook";

const tabs = [
  {
    label: "Beranda",
    icon: "cil:home",
    route: "",
  },
  {
    label: "Program",
    icon: "cil:heart",
    route: "/campaign",
  },
  {
    label: "Akun Saya",
    icon: "bx:user",
    route: "/portal",
  },
  {
    label: "Tentang",
    icon: "cil:life-ring",
    route: "/about",
  },
];

interface FundraisingNavMenuProps {}

const FundraisingNavMenu: FC<FundraisingNavMenuProps> = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const pathname = usePathname();
  const exactRoute = pathname?.split("v2")[1]?.split("/")[1];

  const displayLabel = (label: string) => {
    if (label === "Beranda") {
      return t("word.home");
    } else if (label === "Akun Saya") {
      return t("word.myAcc");
    } else if (label === "Tentang") {
      return t("word.about");
    }
    return label;
  };

  return (
    <nav
      className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full sm:max-w-[480px] z-[1000] h-[64px] p-0 bg-white border-t border-solid border-[#ebebeb]"
      role="navigation"
    >
      <div className="flex flex-row w-full h-full">
        {tabs.map(({ route, label, icon }, index) => {
          return (
            <Link
              key={`tab-${index}`}
              href={createInternalPath(
                `/galang-dana/v2${route}`,
                user.paymeLink
              )}
              className={`flex flex-1 h-full flex-col justify-center items-center ${
                route.split("/")[1] === exactRoute
                  ? "text-blue-500"
                  : "text-gray-500"
              }`}
            >
              <Icon icon={icon} />
              <span className="text-[11px] mt-1">{displayLabel(label)}</span>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default FundraisingNavMenu;
