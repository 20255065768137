"use client";

import React, { FC, useState } from 'react'
import Navigation from './navigation-catalog';
import BannerCatalog from './banner-catalog';
import { IAccount } from '@/composables/account.type';
import { PublicAnnouncementType } from '@/common/types/public-announcement.type';
import { IPaymentLink } from '@/composables/paymentlink.type';
import { IUser } from '@/composables/user.type';

type TopProfileType = {
  username: string;
  user: IUser;
  account: IAccount;
  publicAnnouncement: PublicAnnouncementType;
  images: IPaymentLink[];
  subdomainLink: string;
}
const TopProfile: FC<TopProfileType> = ({ account, images, publicAnnouncement, username, user, subdomainLink }) => {
  const [bannerCount, setBannerCount] = useState(0);
  const banner = publicAnnouncement?.banner ? publicAnnouncement?.banner?.value : false;

  return (
    <>
      <div className="">
        {banner && images?.length > 0 && images?.length - bannerCount > 0 ? (
          <BannerCatalog
            setBannerCount={setBannerCount}
            bannerCount={bannerCount}
            user={user}
            subdomainLink={subdomainLink}
            username={username}
            images={images}
          />
        ) : (
          <></>
        )}
      </div>

      {!banner || images?.length - bannerCount === 0 ? (
        <div className={'container-product-catalog'}>
          <Navigation
            username={username}
            account={account}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default TopProfile;
