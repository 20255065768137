"use client";

import React, { Fragment } from 'react';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GQL_GET_USER_BY_LINK } from '../../../gqls';
const FontLoader = dynamic(() => import("../../font-loader"), { ssr: false });

import { themeOptions } from "../../../assets/linktree-themes";
// import { subdomainName, sanitizeDOM, handleResizeImageUrl } from '../../../utils';

const LinkTree = dynamic(() => import("../../link-tree"), {
  ssr: false,
});
import BrokenLink from '../../broken-link';
import { Icon } from '@iconify/react';
import UserDispatcher from '@/components/dispatcher/user';

const defaultTheme = themeOptions[0];

const Content = ({ username }) => {
  const { t } = useTranslation();
  const subdomainLink = username;

  const { data, error, loading } = useQuery(GQL_GET_USER_BY_LINK, {
    context: { public: true },
    variables: { link: subdomainLink },
  });

  const currentUser = _.get(data, 'getUserByPayme');

  const { fontName } = currentUser?.linkTree?.fontFamily
    ? JSON.parse(currentUser?.linkTree?.fontFamily)
    : defaultTheme.fontFamily;


  const linkTree = _.get(data, 'getUserByPayme.linkTree');
  const userAccount = _.get(data, 'getUserByPayme.account');

  if (loading) return <Icon icon="svg-spinners:270-ring-with-bg" className="text-2xl" />;
  if (error) return <BrokenLink isServerError />;

  if (data && !currentUser.paymeLink) {
    return <BrokenLink message={t('global.user404')} />;
  }

  return (
    <Fragment>
      {currentUser && fontName && <FontLoader fontName={fontName} />}
      <UserDispatcher user={currentUser} />
      {/* <Helmet>
        <title>{sanitizeDOM(userAccount?.name)}</title>
        {userAccount.logo ? <link rel="icon" type="image/png" href={handleResizeImageUrl(userAccount?.logo?.url, 300)} /> : null}
      </Helmet> */}
      <LinkTree data={linkTree} userData={currentUser} username={username} />
    </Fragment>
  );
};

export default Content
