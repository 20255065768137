"use client";

import React, { FC } from 'react'
import { Button } from '@/components/ui/button';

type ButtonLoadMoreType = {
  handleFetch: () => void;
  isLoading: boolean;
}

const ButtonLoadMore: FC<ButtonLoadMoreType> = ({ handleFetch, isLoading }) => {

  const handleRoute = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete('pageSize');
    params.set('pageSize', "9");

    const url = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, '', url);

    window.location.reload();
  }
  return (
    <Button
      variant={'outline-primary'}
      className='text-[10px]'
      onClick={handleFetch}
      disabled={isLoading}
    >
      {isLoading ? 'Loading...' : 'TAMPILKAN LEBIH BANYAK'}
    </Button>
  )
}

export default ButtonLoadMore;
