"use client";

import '../style.scss';
import React, { useState } from 'react';
import { handleResizeImageUrl, isMobileScreen } from '@/utils';
import Image from 'next/image';
type ImageFallBackType = {
  src: string;
  alt: string;
  bannerCount: number;
  setBannerCount: React.Dispatch<React.SetStateAction<number>>;
  className: string;
  idx: number;
}

export const ImageFallBack = (props: ImageFallBackType) => {
  const { src, alt, bannerCount, setBannerCount, className, idx } = props;
  const [error, setError] = useState(false);
  const onError = () => {
    if(idx === 0){
      setBannerCount(bannerCount+1)
    }
    if (!error) {
      setError(true);
    }
  }

  if(error){
    return <></>
  }

  return (
    <div className="banner-items">
      <img
        alt={alt}
        src={src && handleResizeImageUrl(src, isMobileScreen() ? 300 : 700)}
        onError={onError}
        className={className}
        loading="lazy"
      />
    </div>
  );
}
