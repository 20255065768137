"use client";
import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselIndicators,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import { IImage } from "@/composables/image.type";
// import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react'
// import { CarouselApi } from "@/components/ui/carousel";
type MultipleImagesType = {
  multipleImage: IImage[];
};

const MultipleImages = ({
  multipleImage = [],
  ...props
}: MultipleImagesType) => {
  return (
    // aspect ratio to improve CLS on lighthouse peformance audit
    <div
      {...props}
      // className="form rui-sign-form rui-sign-form-cloud p-0 w-full mx-auto"
      // className="form p-0 w-full mx-auto max-w-[400px] bg-white"
      className="p-0 w-full mx-auto bg-[#FFF]"
    >
      <div className="p-0 w-full">
        <Carousel
          plugins={[
            Autoplay({
              delay: 5000,
            }),
          ]}
        >
          <CarouselContent>
            {multipleImage?.map(
              ({ url, updatedAt, width, height }: IImage, index) => (
                <CarouselItem key={index} index={index}>
                  <Image
                    id="product-images-mayar"
                    src={url}
                    alt={`Cover product`}
                    className=""
                    width={width}
                    height={height}
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: width / height,
                      objectFit: "cover",
                    }}
                    quality={70}
                    priority={true}
                  />
                </CarouselItem>
              )
            )}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
          <CarouselIndicators />
        </Carousel>
      </div>
    </div>
  );
};

export default MultipleImages;
