"use client";

import React, { FC, useState } from 'react'
import { PublicAnnouncementType } from '@/common/types/public-announcement.type'
import { MayarAlertBase } from '@/components/ui/mayar-alert-base'
type AnnouncementAlert = {
  publicAnnouncement: PublicAnnouncementType;
}

const AnnouncementAlert: FC<AnnouncementAlert> = ({ publicAnnouncement }) => {
  const [isAlertOn, setIsAlertOn] = useState<boolean>(Boolean(publicAnnouncement?.title))

  if (isAlertOn) {
    return (
      <div className='sticky top-0 left-0 right-0 z-[1000] mb-14'>
        <MayarAlertBase
          className="announcement white-space-enabled fixed top-0 right-0 left-0 z-50 bg-blue-500 text-white"
          onCloseAlert={() => setIsAlertOn(false)}
          noVariant
        >
          <div className="container text-center py-0 mx-auto">
            <p className="mb-0 text-white font-semibold text-[1rem]">{publicAnnouncement?.title}</p>
          </div>
        </MayarAlertBase>
      </div>
    )
  }
}

export default AnnouncementAlert;
