import React, { FC } from "react";
import { FundraisingCategory } from "@/common/types/category.type";

const getColor = (category: FundraisingCategory | string) => {
  switch (category) {
    case "Bantuan Pendidikan":
      return "#219ebc";
    case "Bencana Alam":
      return "#d62828";
    case "Difabel":
      return "#fca311";
    case "Infrastruktur Umum":
      return "#14213d";
    case "Karya Kreatif / Modal Usaha":
      return "#00509d";
    case "Kegiatan Sosial":
      return "#9fa91f";
    case "Kemanusiaan":
      return "#25a18e";
    case "Lingkungan":
      return "#538d22";
    case "Menolong Hewan":
      return "#723d46";
    case "Panti Asuhan":
      return "#47c2be";
    case "Rumah Ibadah":
      return "#ffc200";
    case "Wakaf":
      return "#5fb915";
    default:
      return "#586f7c";
  }
};

export const FundraisingCategoryBadge: FC<{
  category: FundraisingCategory | string;
  className?: string;
}> = (props) => {
  return (
    <div
      style={{ backgroundColor: getColor(props.category) }}
      className={`w-fit px-[5.3px] py-0.5 text-white text-[9px] rounded-[3.25px] ${props.className}`}
    >
      {props.category}
    </div>
  );
};
