"use client";

import React, { Fragment, useState, useEffect, FC, Suspense } from 'react';
import { useSearchParams, useRouter } from 'next/navigation';
import _ from 'lodash';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { GQL_CREATE_PAYMENTTRANSACTION_BY_USERID } from '@/gqls';
import { Controller, useForm, UseFormReturn, FormProvider } from 'react-hook-form';
import Cookies from 'js-cookie';
import {
  handleResizeImageUrl,
  getCurrentDimension,
  decrypt,
  encrypt,
  validator,
  formatRp,
  // sanitizeDOM
} from '@/utils';
import { Input } from '@/components/ui/input';
import SecureBadge from '@/components/secure-badge';
import MayarAlert from '@/components/alert/alert';
import { Textarea } from '@/components/ui/textarea';
import { OneClick } from '@/components/forms';
import { IUser } from '@/composables/user.type';
import { ErrorFeedback } from '@/components/ui/error-feedback';
import { cn } from '@/lib/utils';
import SubmitButton from '@/components/submit-button';
import LoadingAnimation from '@/components/loading-animation';
import PoweredBy from '@/components/powered-by';

type PaymeDetailType = {
  user: IUser;
  paymeLink: string;
}

type FormType = {
  amount: string;
  description: string;
  email: string;
  name: string;
  mobile: string;
}

type SelectedPaymentType = {
  img?: string;
  name?: string;
  status?: boolean;
  type?: string;
  path: string;
  imgWidth?: number
}

const PaymeDetail: FC<PaymeDetailType> = ({ user, paymeLink }) => {
  const form: UseFormReturn<FormType> = useForm();
  const { register, handleSubmit, formState, setError, clearErrors, control } = form;
  const { errors } = formState
  const { t } = useTranslation();
  const router = useRouter()
  const query = useSearchParams();
  const search = query.toString();

  const defaultCustomerCookies = {
    ...(Cookies.get('customerInput') ? decrypt(Cookies.get('customerInput')) : {}),
    ...(query.get('name') && { name: query.get('name') }),
    ...(query.get('email') && { email: query.get('email') }),
    ...(query.get('mobile') && { mobile: query.get('mobile') }),
    ...(query.get('amount') && { amount: query.get('amount') }),
    ...(query.get('message') && { message: query.get('message') }),
  };

  const [
    createPaymentTransactionByUserId,
    { loading: submitLoading, data: pltData },
  ] = useMutation(GQL_CREATE_PAYMENTTRANSACTION_BY_USERID, { context: { public: true } });

  const [selectedPayment, setSelectedPayment] = useState<SelectedPaymentType>();
  const [amountProduct, setAmountProduct] = useState(Number(query.get('amount')));
  const [submitError, setSubmitError] = useState(false);
  const [screenSize, setScreenSize] = useState<{ width: number | false, height: number | false }>(getCurrentDimension());

  const plt = _.get(pltData, 'createPaymentTransactionByUserId');


  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };

    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const getProperWidth = () => {
    let accumulation = 0;

    if (!screenSize.width) {
      return 650;
    }

    if (screenSize.width < 365) {
      accumulation = 200;
    } else if (screenSize.width < 400) {
      accumulation = 240;
    } else if (screenSize.width < 426) {
      accumulation = 290;
    } else if (screenSize.width <= 500) {
      accumulation = 320;
    } else {
      return 650;
    }

    return screenSize.width + accumulation;
  };

  const onSubmit = async (values: any) => {
    let submitValues = { ...values };

    if (defaultCustomerCookies?.isOneClick) {
      delete defaultCustomerCookies.amount;
      delete defaultCustomerCookies.description;

      if (values.email) {
        submitValues = { ...defaultCustomerCookies, ...values };
      } else {
        submitValues = { ...values, ...defaultCustomerCookies };
      }
    }

    const { name, mobile, description } = submitValues;
    const email = submitValues?.email?.toLowerCase();
    const formattedMobile = mobile.replace(/\D/g, '');

    try {
      const res = await createPaymentTransactionByUserId({
        variables: {
          input: {
            paymentLinkName: 'Pay Me',
            email,
            name,
            mobile: formattedMobile,
            description,
            amount: amountProduct,
            userId: user.id,
            paymentType: selectedPayment?.path,
          },
        },
      });

      if (!res.data?.createPaymentTransactionByUserId?.xenditId) {
        throw new Error('Cannot create Transaction');
      }

      Cookies.set(
        'customerInput',
        encrypt({ ...submitValues, paymentData: selectedPayment, isOneClick: true }),
        {
          secure: true,
          expires: 30,
          domain: process.env.NEXT_PUBLIC_BASE_URL,
        },
      );
    } catch (err) {
      setSubmitError(true);
    }
  };

  if (plt?.id) {
    if (selectedPayment?.path.includes('OVO')) {
      router.push(`/plt/${plt?.id}/OVO${search && `?${search}`}`);
    } else if (selectedPayment?.path.includes('JENIUSPAY')) {
      router.push(`/plt/${plt?.id}/JENIUSPAY${search && `?${search}`}`);
    } else if (plt?.xenditId) {
      router.push(`/plt/${plt?.id}/${selectedPayment?.path}/${plt?.xenditId}${search && `?${search}`}`);
    }
  }

  const handlePaymentChange = (e?: SelectedPaymentType) => setSelectedPayment(e);

  const parentState = {
    finalAmount: amountProduct,
    selectedPayment,
    amountProduct,
    defaultCustomerCookies,
  };

  const getValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNumber = /^[0-9\b]+$/;

    const num = Number(e.currentTarget.value.replace(/\./g, '')) || 0;
    console.log({num})
    if (!isNumber.test(String(num))) return;

    return num;
  };
  console.log({amountProduct})
  return (
    <Suspense
      fallback={(
        <div className="form rui-sign-form rui-sign-form-cloud checkout-form">
          <LoadingAnimation profilecard="true" width={getProperWidth()} />
        </div>
      )}
    >
      <div className="bg-[#f8f9fa] min-h-screen flex flex-col items-center justify-center">
        <div className="bg-image">
          <div className="bg-grey-1" />
        </div>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className='form rui-sign-form rui-sign-form-cloud'>
            <div className="justify-center">
              <div className="text-center px-[10px] pt-[20px]">
                {user.account?.logo ? (
                  <Image
                    alt="logo"
                    src={handleResizeImageUrl(user?.account?.logo?.url, 300)}
                    width={65}
                    height={65}
                    priority
                    className="rounded-full mb-[10px] w-[60px] h-[60px] mx-auto"
                  />
                ) : null}

                <h1 className="text-2xl mb-[10px] mt-[15px] text-center">{user.account?.name || user.name}</h1>
              </div>

              <small className='text-[12px] text-center text-[#4b515b]'>{t('payme.enterDetail')}</small>

              {submitError && (
                <div className='px-[10px] pt-[20px]'>
                  <MayarAlert isError={submitError} />
                </div>
              )}

              <div className="w-full pt-[20px] px-[10px]">
                <div className={cn(
                  "checkout-input flex min-h-[36px] items-center border-[1px] rounded-sm overflow-hidden",
                  Boolean(errors.amount) ? "border-[#fac6cc]" : "border-[#e6ecf0]"
                )}>
                  {/* Prefix */}
                  <div className="flex items-center min-h-[36px] h-[40px] px-[12px] rounded-l-none bg-gray-100 text-gray-500 text-[1rem] my-0">
                    Rp
                  </div>
                  {/* Input */}
                  <Controller
                    name="amount"
                    control={control}
                    defaultValue={query.get('amount') ? parseFloat(query.get('amount') || "").toLocaleString('id'): ""}
                    rules={{
                      required: validator.isRequired(true)
                    }}
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                      <Input
                        ref={ref}
                        id="amount"
                        type="number"
                        autoComplete="on"
                        aria-describedby="amount-hint"
                        onChange={(e) => {
                          const num = getValue(e);
                          // const amountDisplay = formatRp(num)
                          const amountDisplay = num?.toLocaleString('id');
                          onChange(num === 0 ? '' : amountDisplay);
                          console.log({num})
                          setAmountProduct(num!); // Custom handler
                        }}
                        value={value}
                        onBlur={onBlur}
                        invalid={Boolean(error)}
                        placeholder={'Jumlah (Rp)'}
                        className={`w-full min-h-[36px] px-[17px] py-[7.5px] text-gray-700 placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-transparent rounded-l-none border-none text-[1rem]`}
                      />
                    )}
                  />
                </div>
                <ErrorFeedback id="amount-hint">{errors.amount?.message}</ErrorFeedback>
              </div>
              <div className='px-[10px] pt-[20px]'>
                <Textarea
                  id="description"
                  // name="description"
                  // type="textarea"
                  autoComplete="on"
                  aria-describedby="description-hint"
                  className="form-control form-control-lg rows-2 !min-h-[5rem] h-[5rem]"
                  placeholder={t('placeholder.descOpt')}
                  defaultValue={query.get('message') || undefined}
                  invalid={Boolean(errors.description)}
                  {...register("description", { minLength: validator.minLength(3) })}
                />
                <ErrorFeedback id="description-hint">{errors.description?.message}</ErrorFeedback>
              </div>

              <OneClick
                useForm={() => form}
                onPaymentChange={handlePaymentChange}
                parentState={parentState}
                // temporary hardcode for pemuda hijrah add user
                paymentLink={{ type: 'payme', payChannelConfig: user?.payChannelConfig, user: user }} onVariantSelected={undefined}
                onChapterSelected={undefined}
                selectedChapter={undefined}
                selectedVariant={undefined}
                amountChangedAfterFee={undefined}
                cartData={undefined}
                specificAmount={undefined}
                setPurchasedQty={undefined}
                isNotOneClick={defaultCustomerCookies}
              />

              <div className='px-[10px] pt-[20px]'>
                <SubmitButton
                  block
                  size="lg"
                  color="primary"
                  type="submit"
                  className="text-center pay-button text-[12px] uppercase w-full rounded-sm"
                  loading
                  isLoading={submitLoading}
                  isDisabled={amountProduct < 1000 || amountProduct > 500_000_000}
                  text={t('word.pay')}
                />
              </div>
              <SecureBadge />
            </div>
          </form>
        </FormProvider>
        <PoweredBy />
      </div>
    </Suspense>
  )
}

export default PaymeDetail;
