"use client";
import { IUser } from "@/composables/user.type";
import { GQL_CREATE_PAYMENTTRANSACTION_BY_USERID } from "@/gqls";
import {
  decrypt,
  encrypt,
  getCurrentDimension,
  handleResizeImageUrl,
  parseJSON,
  truncateText,
  validator,
} from "@/utils";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { useSearchParams, useRouter } from "next/navigation";
import React, { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import * as Form from "@radix-ui/react-form";
import Image from "next/image";
import { MayarAlertBase } from "@/components/ui/mayar-alert-base";
import MayarAlert from "@/components/alert/alert";
import { AmountInput, OneClick } from "@/components/forms";
import { Button } from "@/components/ui/button";
import { EZakatType, QuickDonation } from "@/common/types/fundraising.type";
import { Textarea } from "@/components/ui/textarea";
import SubmitButton from "@/components/submit-button";
import SecureBadge from "@/components/secure-badge";
import PoweredBy from "@/components/powered-by";

const DEFAULT_LOGO_URL = process.env.NEXT_PUBLIC_DEFAULT_USER_AVATAR;

interface PayMeDonationGenericProps {
  user: IUser;
}

const PayMeDonationGenericDetail: FC<PayMeDonationGenericProps> = ({
  user,
}) => {
  const { t } = useTranslation();
  const query = useSearchParams();
  const router = useRouter();
  const form = useForm();
  const { register, handleSubmit, formState, setValue } = form;
  const state = parseJSON(query.get("state") || "", {});

  const [
    createPaymentTransactionByUserId,
    { loading: submitLoading, data: pltData },
  ] = useMutation(GQL_CREATE_PAYMENTTRANSACTION_BY_USERID, {
    context: { public: true },
  });

  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [amountProduct, setAmountProduct] = useState(
    Number(query.get("amount")) || 0
  );
  const [submitError, setSubmitError] = useState(false);
  const [expand, setExpand] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const plt = _.get(pltData, "createPaymentTransactionByUserId");

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  if (plt?.id) {
    if (selectedPayment?.path.includes("OVO")) {
      router.push(`/plt/${plt?.id}/OVO?${query.toString()}`);
    } else if (selectedPayment?.path.includes("JENIUSPAY")) {
      router.push(`/plt/${plt?.id}/JENIUSPAY?${query.toString()}`);
    } else if (plt?.xenditId) {
      router.push(
        `/plt/${plt?.id}/${selectedPayment?.path}/${plt?.xenditId}?${query.toString()}`
      );
    }
  }

  const defaultCustomerCookies = {
    ...(Cookies.get("customerInput")
      ? decrypt(Cookies.get("customerInput"))
      : {}),
    ...(query.get("name") && { name: query.get("name") }),
    ...(query.get("email") && { email: query.get("email") }),
    ...(query.get("mobile") && { mobile: query.get("mobile") }),
    ...(query.get("amount") && { amount: query.get("amount") }),
    ...(query.get("message") && {
      message: query.get("message"),
    }),
  };

  const toggleExpand = () => setExpand(!expand);
  const isTypeZakat = state?.paymentLinkType === "zakat";

  const onSubmit = async (values: FieldValues) => {
    let submitValues = { ...values };

    if (defaultCustomerCookies?.isOneClick) {
      delete defaultCustomerCookies.amount;
      delete defaultCustomerCookies.description;

      if (values.email) {
        submitValues = { ...defaultCustomerCookies, ...values };
      } else {
        submitValues = { ...values, ...defaultCustomerCookies };
      }
    }

    if (!values.description) {
      submitValues.description = state?.paymentLinkName || "PayMe Donation";
    }

    const { name, mobile, description } = submitValues;
    const email = submitValues?.email?.toLowerCase();
    const mobileNumber = mobile.replace(/\D/g, "");

    try {
      const res = await createPaymentTransactionByUserId({
        variables: {
          input: {
            email,
            name,
            mobile: mobileNumber,
            description,
            ...(!!isTypeZakat
              ? {
                  paymentLinkName: state?.paymentLinkName,
                  paymentLinkType: "zakat",
                  subType: state?.subType,
                }
              : { paymentLinkName: "PayMe Donation" }),
            amount: amountProduct,
            userId: user.id,
            paymentType: selectedPayment?.path,
          },
        },
      });

      if (!res.data?.createPaymentTransactionByUserId?.xenditId) {
        throw new Error("Cannot create Transaction");
      }

      Cookies.set(
        "customerInput",
        encrypt({
          ...submitValues,
          paymentData: selectedPayment,
          isOneClick: true,
        }),
        {
          secure: true,
          expires: 30,
          domain: process.env.NEXT_PUBLIC_BASE_URL,
        }
      );
    } catch (err) {
      setSubmitError(true);
    }
  };

  const handleQuickAmount = (amount: number) => {
    setAmountProduct(amount);
    setValue("amount", parseFloat(amount.toString()).toLocaleString("id"));
  };

  const parentState = {
    finalAmount: amountProduct,
    selectedPayment,
    amountProduct,
    defaultCustomerCookies,
  };
  const quickDonations: QuickDonation[] = user?.fundraisingSettings
    ?.quickDonations
    ? JSON.parse(user.fundraisingSettings.quickDonations)
    : [];

  return (
    <FormProvider {...form}>
      <div className="rui-sign items-center bg-[#f8f9fa]">
        <div className="sm:max-w-[400px] mx-auto bg-white h-full flex flex-col">
          <Form.Root
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col rui-sign-form rui-sign-form-cloud !p-10"
          >
            <div className="flex flex-col items-center">
              {user?.account?.logo && (
                <Image
                  alt="Merchant Logo"
                  src={
                    handleResizeImageUrl(user.account.logo.url, 200) ||
                    DEFAULT_LOGO_URL
                  }
                  width={110}
                  height={110}
                  className="rounded-full h-[110px]"
                />
              )}

              <span className="text-[22px] mb-2.5 mt-5 text-center font-medium flex gap-2 items-center">
                {user.account.name || user.name}{" "}
                {user.account.isVerified && (
                  <Image
                    width={17}
                    height={17}
                    src="/check.png"
                    alt="Verified account"
                    className="h-[17px]"
                  />
                )}
              </span>

              {!user.account.profile &&
              !user.account.address &&
              !user.account.mobile &&
              !user.mobile ? (
                ""
              ) : (
                <MayarAlertBase
                  variant="light"
                  isCloseable={false}
                  className="text-center w-full"
                >
                  {user.account.profile ? (
                    <>
                      {user.account.profile.length < 100 ? (
                        <p>{user.account.profile}</p>
                      ) : (
                        <p>
                          {user.account.profile && expand
                            ? user.account.profile
                            : truncateText(user.account.profile, 100)}
                        </p>
                      )}{" "}
                      {user.account.profile.length > 100 && (
                        <button
                          className="font-medium text-blue-500 bg-transparent border-0 p-0 capitalize mt-1.5"
                          type="button"
                          onClick={toggleExpand}
                          onKeyDown={toggleExpand}
                        >
                          {`(${expand ? t("global.less") : t("global.more")})`}
                        </button>
                      )}
                    </>
                  ) : (
                    <span>{t("global.noProfile")}</span>
                  )}

                  <hr className="my-3" />
                  <strong>
                    {!user.account.address &&
                    !user.account.mobile &&
                    !user.mobile
                      ? "-"
                      : `${user.account.address || "-"} | ${
                          user.account.mobile || user.mobile || "-"
                        }`}
                  </strong>
                </MayarAlertBase>
              )}
            </div>

            <h1 className="text-sm font-medium mx-auto mt-[30px] mb-5">
              {t(isTypeZakat ? "zakat.title" : "donation.title")}
            </h1>

            {submitError && (
              <MayarAlert customClass="my-5" isError={submitError}></MayarAlert>
            )}

            <Form.Field name="amount" className="flex flex-col mb-2.5">
              <div className="flex">
                <div className="rounded-md rounded-r-none h-10 border border-r-0 border-input px-3 py-2 text-sm bg-gray-100">
                  <span className="text-[#6c757d] text-base">Rp</span>
                </div>
                <AmountInput
                  placeholder={t("placeholder.amount")}
                  defaultValue={Number(query.get("amount"))}
                  onValueChange={setAmountProduct}
                  isRequired
                />
              </div>
              {typeof formState.errors?.amount?.message === "string" && (
                <Form.Message
                  id="amount-hint"
                  className="text-red-500 text-[11px] mt-1"
                >
                  {formState.errors.amount.message}
                </Form.Message>
              )}
            </Form.Field>

            {quickDonations.length > 0 && (
              <div className="flex gap-2 mb-6">
                {quickDonations.map((item, index: number) => (
                  <Button
                    type="button"
                    key={item.position}
                    variant="light"
                    size="sm"
                    onClick={() => handleQuickAmount(item.amount)}
                    className="text-center flex-1 text-xs"
                  >
                    {`${item.amount.toLocaleString("id")}`}
                  </Button>
                ))}
              </div>
            )}

            <Form.Field name="description" className="flex flex-col">
              <Textarea
                {...register("description", {
                  minLength: validator.minLength(3),
                })}
                className="text-base"
                autoComplete="on"
                aria-describedby="description-hint"
                defaultValue={query.get("message") || ""}
                invalid={Boolean(formState.errors.description)}
                placeholder={t(
                  `placeholder.${state?.subType === EZakatType.FITR ? "zakatFitr" : "messageOpt"}`
                )}
              />

              {typeof formState.errors?.description?.message === "string" && (
                <Form.Message
                  id="description-hint"
                  className="text-red-500 text-[11px] mt-1"
                >
                  {formState.errors.description.message}
                </Form.Message>
              )}
            </Form.Field>

            <div className="mb-5 -mx-2.5">
              {/* @ts-ignore */}
              <OneClick
                useForm={() => form}
                onPaymentChange={setSelectedPayment as () => void}
                parentState={parentState}
                // temporary hardcode for pemuda hijrah add user
                paymentLink={{
                  type: "zakat",
                  payChannelConfig: user?.payChannelConfig,
                  user: user,
                }}
                isNotOneClick={!defaultCustomerCookies?.isOneClick}
              />
            </div>

            <SubmitButton
              size="lg"
              block
              color="primary"
              type="submit"
              loading
              className="uppercase text-[13px]"
              isLoading={submitLoading}
              isDisabled={amountProduct < 1000 || amountProduct > 10000000}
              text={`${t("word.pay")} ${isTypeZakat ? "Zakat" : t("word.donation")}`}
            />

            <SecureBadge />

            <MayarAlertBase
              variant="light"
              isCloseable={false}
              className="mt-5 text-center leading-6"
            >
              <strong>Disclaimer:</strong>
              <br /> {t("donation.disclaimer")} <br />
              {t("donation.disclaimer2")}{" "}
              <a href="mailto:bantuan@mayar.id" className="text-blue-500">
                {t("word.report")}
              </a>
            </MayarAlertBase>
          </Form.Root>
        </div>

        <PoweredBy className="py-5 mt-0" />
      </div>
    </FormProvider>
  );
};

export default PayMeDonationGenericDetail;
