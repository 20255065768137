"use client";

import './style.scss';

import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import InnerHTML from 'dangerously-set-html-content';
// import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { REGEX_UUID, subdomainName } from '../../../utils';
import BrokenLink from '../../broken-link';
import { conversionPageView } from '@/utils/conversion-settings';
import LoadingAnimation from '../../loading-animation';
import { GET_USER_BY_LINK } from '../../../gqls';
import UserDispatcher from '@/components/dispatcher/user';

const LandingPage = ({ id, username }) => {
  const { t } = useTranslation();
  const [landingPage, setLandingPage] = useState({});
  const [conversionSetting, setConversionSetting] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const subdomainLink = username;

  const { data: dataUser, loading: loadingUser, error: errorUser } = useQuery(GET_USER_BY_LINK, {
    context: { public: true },
    variables: { link: subdomainLink },
  });

  const currentUser = _.get(dataUser, 'getUserByPayme');

  const identifier = { id: null, link: null };

  if (REGEX_UUID.test(id)) {
    identifier.id = id;
  } else {
    identifier.link = id;
  }

  useEffect(() => {
    if (currentUser) {
      Axios.get(
        `${process.env.NEXT_PUBLIC_MAYAR_USERKV_S3_ENDPOINT}/landing-page/${
          identifier.id ? 'data' : 'data-link'
        }/${process.env.NEXT_PUBLIC_MAYAR_FOLDER_ENV}/${
          identifier.id || identifier.link || currentUser?.homePagePath?.split('/')[1]
        }.json`,
      )
        .then((res) => {
          setLandingPage(res.data);

          if (res.data?.conversionSettings?.length > 0) {
            setConversionSetting(res.data.conversionSettings[0]);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else if (!currentUser && !loadingUser && !errorUser) {
      setLandingPage({ errorCode: 404 });
    }
  }, [currentUser]);

  useEffect(() => {
    conversionPageView(conversionSetting);
  }, [conversionSetting]);

  if (error || errorUser) return <BrokenLink isServerError />;

  if (loading || loadingUser)
    return (
      <div className="responsivearticle">
        <LoadingAnimation responsivearticle="true" />;
      </div>
    );

  const { metaTitle, htmlResult, status, user, name, type } = landingPage;
  console.log({status, type, user, currentUser, landingPage})
  if (
    status === 'inactive' ||
    status === 'archived' ||
    type === 'BASIC' ||
    user?.id !== currentUser?.id ||
    landingPage.errorCode === 404
  ) {
    return <BrokenLink message={t('alert.link404')} />;
  }

  return (
    <Fragment>
      {/* <Helmet>
        <title>
          {`${sanitizeDOM(metaTitle) || sanitizeDOM(name)} - ${sanitizeDOM(user?.account?.name)}`}
        </title>
        {user?.account?.logo && <link rel="icon" type="image/png" href={user.account.logo.url} />}
      </Helmet> */}
      <UserDispatcher user={currentUser}/>
      <InnerHTML html={htmlResult} />
    </Fragment>
  );
};

export default LandingPage;
